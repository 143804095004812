/** 商品のデータ */
export default class Product {
  readonly no: string | null
  readonly category: string | null
  readonly subCategory: string | null
  readonly categoryClass: string | null
  readonly id: string | null
  readonly name: string | null
  readonly jan: string | null
  readonly images: string[]
  readonly price_t: string | null
  readonly price_b: string | null
  readonly description: string | null
  readonly note: string | null
  readonly recommended: string[]
  readonly link: string | null

  /** @constructor */
  constructor(data: {
    no?: string
    category?: string
    subCategory?: string
    categoryClass?: string
    id?: string
    name?: string
    jan?: string
    images?: string
    price_t?: string
    price_b?: string
    description?: string
    note?: string
    recommended?: string
    link?: string
  }) {
    this.no = data.no ?? null
    this.category = data.category?.trim() ?? null
    this.subCategory = data.subCategory?.trim() ?? null
    this.categoryClass = data.categoryClass?.trim() ?? null
    this.id = data.id?.trim() ?? null
    this.name = data.name?.trim() ?? null
    this.jan = data.jan?.trim() ?? null
    this.images = data.images?.trim()?.split('\n') ?? []
    this.price_t = data.price_t ?? null
    this.price_b = data.price_b ?? null
    this.description = data.description?.trim() ?? null
    this.note = data.note?.trim() ?? null
    this.recommended = data.recommended?.trim()?.split(',') ?? []
    this.link = data.link?.trim() ?? null
  }

  /** 表示用の`￥12,345`形式の価格 */
  get totalPriceForDisplay() {
    if (!this.price_t) return undefined
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(parseInt(this.price_t, 10))
  }

  /** 表示用の`￥12,345`形式の価格 */
  get basePriceForDisplay() {
    if (!this.price_b) return undefined
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(parseInt(this.price_b, 10))
  }

  /** 画像のパス */
  get imagePaths() {
    if (!this.category) return []

    const map: ReadonlyMap<string, string> = new Map([
      ['コーヒーサイフォン', 'coffee_syphon'],
      ['名門フィルター', 'meimon_filter'],
      ['コーヒーサーバー', 'coffee_server'],
      ['コーヒー豆', 'roasted_coffee'],
      ['コーヒー器具', 'coffee_material'],
    ])
    const slug = map.get(this.category)
    if (!slug) return []

    return this.images.map(v => `/images/product/${slug}/${this.jan}/${v}`)
  }
}
