import News from './news'

/** お知らせのデータ配列 */
export default class NewsCollection extends Array<News> {
  /**
   * カテゴリーで絞り込む
   * @param {string} カテゴリー名
   */
  filterByCategory(category: string) {
    return new NewsCollection(...this.filter(row => row.category === category))
  }

  /**
   * カテゴリーの一覧を返す
   * @returns {string[]} カテゴリー名の文字列の配列
   */
  get categories(): string[] {
    return [...new Set(this.map(row => row.category).filter((row): row is string => row != undefined))]
  }

  /**
   * 最新のお知らせデータを返す
   * @returns {News|undefined}
   */
  get latest(): News | undefined {
    return this.sort((a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0))?.[0]
  }
}
