import dayjs from 'dayjs'

/** お知らせのデータ */
export default class News {
  readonly f_top: boolean
  readonly date: Date | null
  readonly category: string | null
  readonly title: string | null
  readonly note: string | null
  readonly filename: string | null

  /** @constructor */
  constructor(data: {
    f_top?: string
    date?: string | Date
    category?: string
    title?: string
    note?: string
    filename?: string
  }) {
    this.f_top = !!data.f_top
    if (typeof data.date === 'string') {
      const _date = dayjs(data.date).toDate()
      this.date = _date.toString() !== 'Invalid Date' ? _date : null
    } else {
      this.date = data.date ?? null
    }
    this.category = data.category?.trim() ?? null
    this.title = data.title?.trim() ?? null
    this.note = data.note?.trim() ?? null
    this.filename = data.filename?.trim() ?? null
  }

  /** 表示用の`YYYY.MM.DD`形式の日付 */
  get dateForDisplay() {
    return dayjs(this.date ?? undefined).format('YYYY.MM.DD')
  }
}
