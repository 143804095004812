export default class ContactForm {
  /** 入力データ */
  input = {
    contactKind: '',
    contactCorporateName: '',
    contactName: '',
    contactMail: '',
    contactConfirmationMail: '',
    contactTel1: '',
    contactTel2: '',
    contactTel3: '',
    contactNote: '',
  }

  /** エラー */
  error: {
    contactKind?: string
    contactName?: string
    contactMail?: string
    contactTel?: string
    contactNote?: string
  } = {}

  /** @constructor */
  constructor(init?: {
    contactKind?: string
    contactName?: string
    contactMail?: string
    contactTel?: string
    contactNote?: string
  }) {
    Object.assign(this.input, init)
  }

  /** エラー検証する */
  validate() {
    const pattern = {
      mail: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/i,
    }

    this.error.contactKind = this.input.contactKind === '' ? 'お問い合わせ内容は必須項目です' : undefined

    this.error.contactName = this.input.contactName === '' ? 'お名前は必須項目です' : undefined

    this.error.contactMail =
      this.input.contactMail === ''
        ? 'メールアドレスは必須項目です'
        : !pattern.mail.test(this.input.contactMail)
        ? '正しいメールアドレスを入力してください'
        : this.input.contactMail !== this.input.contactConfirmationMail
        ? '確認メールアドレスが異なっています'
        : undefined

    if (this.input.contactTel1 !== '' || this.input.contactTel2 !== '' || this.input.contactTel3 !== '') {
      // 電話番号のいずれかが入力されてる場合
      this.error.contactTel = isValidTel(this.input.contactTel1, this.input.contactTel2, this.input.contactTel3)
        ? undefined
        : '正しい電話番号を入力してください'
    } else {
      // 電話番号の入力が空の場合
      this.error.contactTel = undefined
    }

    this.error.contactNote = this.input.contactNote === '' ? '自由記入欄は必須項目です' : undefined
  }

  /**
   * エラーの有無を返す
   */
  get hasError() {
    return Object.values(this.error).filter(val => val != undefined).length > 0
  }

  /**
   * エラーをリセットする
   */
  resetError() {
    const error = this.error
    type Keys = keyof typeof error

    for (const key of Object.keys(error) as Keys[]) {
      delete this.error[key]
    }
  }

  /**
   * 送信用のフォーム項目
   */
  get inputForSubmission() {
    return {
      contactKind: this.input.contactKind,
      contactCorporateName: this.input.contactCorporateName,
      contactName: this.input.contactName,
      contactMail: this.input.contactMail,
      contactTel: `${this.input.contactTel1}-${this.input.contactTel2}-${this.input.contactTel3}`,
      contactNote: this.input.contactNote,
    }
  }
}

/**
 * 電話番号の検証
 *
 * 電話番号のルールについては総務省のサイトを参考。
 * @see {@link https://www.soumu.go.jp/main_sosiki/joho_tsusin/top/tel_number/q_and_a.html}
 *
 * @param tel1 ハイフン区切り1つ目の入力フィールド
 * @param tel2 ハイフン区切り2つ目の入力フィールド
 * @param tel3 ハイフン区切り3つ目の入力フィールド
 * @returns 有効なら`true`、無効なら`false`
 */
function isValidTel(tel1: string, tel2: string, tel3: string) {
  if (/^0[789]0$/.test(tel1)) {
    // 携帯電話番号（'090'or'080'or'070'）の場合
    if (/^\d{4}$/.test(tel2) && /^\d{4}$/.test(tel3)) {
      // 3桁-4桁-4桁の場合は有効な電話番号
      return true
    } else {
      // それ以外の場合は無効な電話番号
      return false
    }
  } else if (/^050$/.test(tel1)) {
    // IP電話（'050'）の場合
    if (/^\d{3,4}$/.test(tel2) && /^\d{4}$/.test(tel3)) {
      // 3桁-[3桁or4桁]-4桁の場合は有効な電話番号
      // ※10桁の番号は昔の名残
      return true
    } else {
      // それ以外の場合は無効な電話番号
      return false
    }
  } else if (/^0\d{1,4}$/.test(tel1) && /^\d{1,4}$/.test(tel2) && /^\d{6}$/.test(tel1 + tel2) && /^\d{4}$/.test(tel3)) {
    // 固定電話の場合
    // 下記の条件を満たす場合は有効な電話番号
    // ※ 一番目：市外局番、二番目：市内局番、三番目：加入者番号
    // 1. 一番目が「0[1～4桁]」（合計2～5桁）
    // 2. 二番目が「1～4桁」
    // 3. 一番目＋二番目が「6桁」
    // 4. 三番目が「4桁」
    return true
  } else if (/^0\d\d0/.test(tel1) && /^\d{6}$/.test(tel2 + tel3)) {
    // フリーダイヤルの場合
    // 下記の条件を満たす場合は有効な電話番号
    // 1. 一番目が「0[2桁]0」（合計4桁）
    // 2. 二番目＋三番目が「6桁」
    return true
  } else {
    // それ以外の場合は無効な電話番号
    return false
  }
}
