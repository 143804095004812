import Product from './product'

/** 商品のデータ配列 */
export default class ProductCollection extends Array<Product> {
  /**
   * janからアイテムを取得する
   * @param {string} productId - jan
   * @returns {Product|undefined} 商品データ
   */
  get(productId: string) {
    return this.find(row => row.jan === productId)
  }

  /**
   * 商品の通し番号からアイテムを取得する
   * @param {number} no - 通し番号
   * @returns {Product|undefined} 商品データ
   */
  getByNo(no: string) {
    return this.find(row => row.no === no)
  }

  /**
   * サブカテゴリーから商品データを絞り込む
   * @param {string} subCategory - サブカテゴリー名
   * @returns {ProductCollection} 商品データの配列
   */
  filterBySubCategory(subCategory: string) {
    return new ProductCollection(...this.filter(row => row.subCategory === subCategory))
  }

  /**
   * カテゴリー仕分け名から商品データを絞り込む
   * @param {string} categoryClass - サブカテゴリー名
   * @returns {ProductCollection} 商品データの配列
   */
  filterByClass(categoryClass: string) {
    return new ProductCollection(...this.filter(row => row.categoryClass === categoryClass))
  }

  /**
   * カテゴリーの一覧を取得する
   * @returns {string[]} カテゴリーの配列
   */
  get categories() {
    return [...new Set(this.map(row => row.category))].filter((row): row is string => row != undefined)
  }

  /**
   * サブカテゴリーの一覧を取得する
   * @returns {string[]} サブカテゴリーの配列
   */
  get subCategories() {
    return [...new Set(this.map(row => row.subCategory))].filter((row): row is string => row != undefined)
  }

  /**
   * カテゴリー仕分けの一覧を取得する
   * @returns {string[]} カテゴリー仕分けの配列
   */
  get categoryClasses() {
    return [...new Set(this.map(row => row.categoryClass))].filter((row): row is string => row != undefined)
  }
}
